<template>
  <div>
    <v-toolbar light>
      <v-toolbar-title>
        Manage Users
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" to="/main/admin/users/create">Create User</v-btn>
    </v-toolbar>
    <v-data-table :headers="headers" :items="users">
      <template slot="items" slot-scope="props">
        <tr>
          <td class="text-xs-right">{{ props.item.id }}</td>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.full_name }}</td>
          <td class="text-xs-right">{{ props.item.balance.toFixed(2) }}</td>
          <td><v-icon v-if="props.item.is_active">checkmark</v-icon></td>
          <td><v-icon v-if="props.item.is_superuser">checkmark</v-icon></td>
          <td class="justify-center layout px-0">
            <v-tooltip top>
              <span>Edit</span>
              <v-btn slot="activator" flat :to="{name: 'main-admin-users-edit', params: {id: props.item.id}}">
                <v-icon>edit</v-icon>
              </v-btn>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <tr>
          <td colspan="3" class="text-right"><strong>Total Balance:</strong></td>
          <td class="text-xs-right">{{ totalBalance.toFixed(2) }}</td>
          <td colspan="3"></td> <!-- Empty cells for alignment -->
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Full Name',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Balance',
      sortable: true,
      value: 'balance',
      align: 'center',
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: 'Is Superuser',
      sortable: true,
      value: 'isSuperuser',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];

  get users() {
    return readAdminUsers(this.$store);
  }

  get totalBalance() {
    return this.users.reduce((sum, user) => sum + user.balance, 0);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }
}
</script>

<style scoped>
.total-balance {
  margin-top: 16px;
  font-size: 1.2em;
}
</style>
