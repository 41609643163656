<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Top Up</div>
      </v-card-title>
      <v-card-text>
        <div class="float-left">
          <div class="subheading secondary--text text--lighten-3">Balance / Min Balance / Available Balance (THB)</div>
          <div class="title primary--text text--darken-2" v-if="userProfile">
            {{userProfile.balance.toFixed(2)}} /
            {{userProfile.min_balance.toFixed(2)}} /
            {{(userProfile.balance - userProfile.min_balance).toFixed(2)}}
          </div>
          <div class="title primary--text text--darken-2" v-else>-- / -- / --</div>
        </div>
        
        <v-select
          v-model="mode"
          :items="modes"
          label="Select Mode"
        ></v-select>

        <template>
          <v-form ref="form">
            <v-select
              required
              label="Operator"
              v-model="operator"
              :items="operatorItems"
              @input="$v.operator.$touch()"
              @blur="$v.operator.$touch()"
              @change="resetSelectedPackage"
            ></v-select>

            <v-text-field
              required
              label="Phone Number: "
              v-model="phone"
              hint="example: 0123456789"
              :error-messages="phoneErrors"
              @input="$v.phone.$touch()"
              @blur="$v.phone.$touch()"
            ></v-text-field>

            <template v-if="mode === 'topup'">
              <v-text-field
                required
                label="Amount"
                v-model="amount"
                :error-messages="amountErrors"
                @input="$v.amount.$touch()"
                @blur="$v.amount.$touch()"
                hint="A number divisible by 10"
              ></v-text-field>
            </template>
            <template v-else>
              <v-select
                required
                label="Package"
                v-model="selectedPackage"
                :items="getPackageItems"
                @blur="$v.selectedPackage.$touch()"
                @change="updateDetail"
              ></v-select>
              <div v-if="packageDetail">
                <pre class="custom-pre">{{ packageDetail }}</pre>
              </div>
            </template>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn @click="submit" :disabled="$v.$invalid">
          {{ mode === 'topup' ? 'TOP UP' : 'BUY PACKAGE' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="subheading secondary--text text--lighten-3">Top-Up History</div>
    <v-data-table :headers="headers"
                  :items="topups"
                  :must-sort="true"
                  :custom-sort="customSort">
      <template v-slot:items="props">
        <td>{{ props.item.created_at }}</td>
        <td>{{ props.item.type }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.destination }}</td>
        <td>{{ props.item.operator }}</td>
        <td :class="getStatusClass(props.item.status)">{{ props.item.status }}</td>
        <td>{{ props.item.note }}</td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import { api } from '@/api';
import { Component, Vue } from 'vue-property-decorator';
import { IBuyPackage, ITopup } from '@/interfaces';
import { readUserProfile, readTopups, readMobilePackages } from '@/store/main/getters';
import { dispatchTopupAis, dispatchTopupDtac, dispatchTopupTrue, dispatchGetTopups, dispatchGetMobilePackages, dispatchBuyPackage } from '@/store/main/actions';
import { required } from 'vuelidate/lib/validators';

@Component
export default class Topup extends Vue {
  mode = 'topup';
  modes = [
    { text: 'Top Up', value: 'topup' },
    { text: 'Buy Package', value: 'buy_package' }
  ];
  operatorItems = ['AIS', 'DTAC', 'TRUE'];
  selectedPackage: string = '';
  operator = 'AIS';
  phone: string = '';
  amount: number = 10;
  headers = [
    { text: 'Time', value: 'created_at', align: 'left' },
    { text: 'Type', value: 'type', align: 'left' },
    { text: 'Amount', value: 'amount', align: 'right' },
    { text: 'Phone Number', value: 'destination', align: 'left' },
    { text: 'Operator', value: 'operator', align: 'left' },
    { text: 'Status', value: 'status', align: 'left' },
    { text: 'Note', value: 'note', align: 'left' },
  ];

  validations() {
    return {
      operator: { required },
      phone: {
        required,
        validFormat(value: string) {
          return /0[0-9]{9,9}/.test(value);
        },
        validOperator: async (value: string) => {
          // TODO: need to fix the root cause {
          return true
          // }

          try {
            if (this.operator == 'AIS') {
              const response = await api.verifyNumber(this.$store.state.main.token, value);
              return response.data.carrier === this.operator;
            } else {
              // we do not verify True and Dtac phone numbers for now
              return true;
            }
          } catch (e) {
            return false;
          }
        }
      },
      amount: {
        required,
        div10(value) { return value % 10 === 0; },
      },
      selectedPackage: {
        requiredWhenBuyPackage: (value: string) => {
          if (this.mode === 'buy_package')
            return this.selectedPackage;
          else
            return true;
        }
      },
    };
  }

  get phoneErrors() {
    if (! this.$v.phone.required)
      return ['Required'];
    if (! this.$v.phone.validFormat)
      return ['Invalid phone number'];
    if (! this.$v.phone.validOperator)
      return ['The operator of this phone number is not ' + this.operator];
  }

  get amountErrors() {
    if (! this.$v.amount.required)
      return ['Required'];
    if (! this.$v.amount.div10)
      return ['The amount must be divisible by 10.'];
  }

  get getPackageItems() {
    const sections = this.mobilePackages[this.operator] || [];
    return sections.flatMap(section => {
      return section.packages.map(pkg => ({
          text: `${section.section_title}: ${pkg.name}`,
          value: pkg.price,
          detail: pkg.detail
        }));
    });
  }

  public customSort(items, index, isDesc) {
    items.sort((a, b) => {
      if (isDesc) {
        return a[index] < b[index] ? -1 : 1;
      } else {
        return b[index] < a[index] ? -1 : 1;
      }
    });
    return items;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    this.$v.$touch();

    if (this.$v.$invalid)
      return;

    if (this.mode === 'topup') {
      const topupData: ITopup = {
        amount: this.amount,
        phone: this.phone
      };
      return await {
        'AIS': dispatchTopupAis,
        'DTAC': dispatchTopupDtac,
        'TRUE': dispatchTopupTrue,
      }[this.operator](this.$store, topupData);
    } else if (this.mode === 'buy_package') {
      const buyPackageData: IBuyPackage = {
        operator: this.operator,
        price: this.selectedPackage,
        phone: this.phone,
      };
      return await dispatchBuyPackage(this.$store, buyPackageData);
    }
  }

  get topups() {
    return readTopups(this.$store);
  }

  get mobilePackages() {
    return readMobilePackages(this.$store);
  }

  // TODO: need to implement dynamic pagination
  //       See https://vuetifyjs.com/en/components/data-tables/#server-side-paginate-and-sort
  //       and https://blog.logrocket.com/vue-typescript-tutorial-examples/
  async mounted() {
    await dispatchGetTopups(this.$store);
    await dispatchGetMobilePackages(this.$store);
  }

  getStatusClass(status) {
    switch (status) {
      case 'COMPLETE':
      case 'REFUNDED':
        return 'green--text'; // Vuetify class for green text
      case 'FAILED':
        return 'red--text'; // Vuetify class for red text
      default:
        return 'orange--text'; // Vuetify class for orange text
    }
  }

  resetSelectedPackage() {
    this.selectedPackage = '';
    this.packageDetail = '';
  }

  packageDetail: string = '';
  updateDetail() {
    const selected = this.getPackageItems.find(item => item.value === this.selectedPackage);
    this.packageDetail = selected ? selected.detail : '';
  }
}
</script>

<style scoped>
.custom-pre {
  font-family: inherit; /* Use the same font as the rest of the page */
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  margin: 0; /* Remove default margin */
}
</style>
